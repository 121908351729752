<template>
  <v-app>
    <LandingPageBanner></LandingPageBanner>
    <Navbar></Navbar>
    <v-container>
      <template>
        <v-row justify="center">
          <v-col
              cols="12"
              lg="4"
              md="4"
              sm="6"
          >
            <v-hover
                v-slot="{ hover }"
                open-delay="200"
            >
              <v-card
                  :elevation="hover ? 16 : 2"
                  :class="{ 'on-hover': hover }"
                  class="mx-auto variation1 neumorphic"
                  height="500"
                  max-width="280"
              >
                <br>
                <div align="center">
                  <div class="rounded-circle img pt-4">
                    <v-img
                        class="rounded"
                        max-height="100"
                        max-width="100"
                        :src="vision">

                    </v-img>
                  </div>
                </div>
                <v-card-title class="text-center align-center justify-center">Vision</v-card-title>
                <v-card-text class="font-weight-medium text-center text-subtitle-1">
                  To Collaborate with Skilled professionals form multiple verticals of indian
                  real state sector providing home buyers a convenient and anchors experience
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>

          <v-col
              cols="12"
              lg="4"
              md="4"
              sm="6"
          >
            <v-hover
                v-slot="{ hover }"
                open-delay="200"
            >
              <v-card
                  :elevation="hover ? 16 : 2"
                  :class="{ 'on-hover': hover }"
                  class="mx-auto variation1 neumorphic"
                  height="550"
                  max-width="280"
              >
                <br>
                <div align="center">
                  <div class="rounded-circle img pt-4">
                    <v-img
                        class="rounded"
                        max-height="100"
                        max-width="100"
                        :src="mission">

                    </v-img>
                  </div>
                </div>
                <v-card-title class="text-center align-center justify-center">Mission</v-card-title>
                <v-card-text class="font-weight-medium text-center text-subtitle-1">
                  To Collaborate with Skilled professionals form multiple verticals of indian
                  real state sector providing home buyers a convenient and anchors experience
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
          <v-col
              cols="12"
              lg="4"
              md="4"
              sm="6"
          >
            <v-hover
                v-slot="{ hover }"
                open-delay="200"
            >
              <v-card
                  :elevation="hover ? 16 : 2"
                  :class="{ 'on-hover': hover }"
                  class="mx-auto variation1 neumorphic"
                  height="500"
                  max-width="280"
              >
                <br>
                <div align="center">
                  <div class="rounded-circle img pt-4">
                    <v-img
                        class="rounded"
                        max-height="100"
                        max-width="100"
                        :src="goal">

                    </v-img>
                  </div>
                </div>
                <v-card-title class="text-center align-center justify-center">Goals</v-card-title>
                <v-card-text class="font-weight-medium text-center text-subtitle-1">
                  To Collaborate with Skilled professionals form multiple verticals of indian
                  real state sector providing home buyers a convenient and anchors experience
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </template>
      <v-spacer></v-spacer>
    </v-container>
    <br>
    <br>
    <br>
    <br>
    <v-spacer></v-spacer>
    <v-container style="background-color: #2D2D2D">
      <v-spacer></v-spacer>
      <br>
      <br>
      <v-card-title class="justify-center" style="color: white">What Does axles Stand for ?</v-card-title>
      <br>
      <br>
      <v-row justify="center">
        <v-col cols="12" md="2" sm="4">
          <div>
            <v-img :src="ethics"
                   max-width="50"
                   max-height="50">
            </v-img>
            <br>
            <v-spacer></v-spacer>
            <v-card-title style="color: white" class="pa-0">
              Ethics
            </v-card-title>
          </div>
        </v-col>
        <v-col cols="12" md="2" sm="4">
          <div>
            <v-img :src="innovation"
                   max-width="50"
                   max-height="50">
            </v-img>
            <br>
            <v-spacer></v-spacer>
            <v-card-title style="color: white" class="pa-0">
              Innovation
            </v-card-title>
          </div>
        </v-col>
        <v-col cols="12" md="2" sm="4">
          <div>
            <v-img :src="collaboration"
                   max-width="70"
                   max-height="70">
            </v-img>
            <br>
            <v-spacer></v-spacer>
            <v-card-title style="color: white" class="pa-0">Collaboration</v-card-title>
          </div>
        </v-col>
        <v-col cols="12" md="2" sm="4">
          <div>
            <v-img :src="quality"
                   max-width="50"
                   max-height="50">
            </v-img>
            <br>
            <v-spacer></v-spacer>
            <v-card-title style="color: white" class="pa-0">
              Quality & Comfort
            </v-card-title>
          </div>
        </v-col>
        <v-col cols="12" md="2" sm="4">
          <div class="ml-5">
            <v-spacer></v-spacer>
            <v-img :src="sustainability"
                   style="align-content: end"
                   max-width="60"
                   max-height="60">
            </v-img>
            <br>
            <v-spacer></v-spacer>
            <h2 style="color: white">Sustainability
            </h2>
          </div>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <br>
      <br>
    </v-container>
    <v-spacer></v-spacer>
    <br>
    <br>
      <v-carousel
          v-model="model"
          vertical
          continuous
          hide-delimiter-background
          show-arrows-on-hover

      >
        <v-carousel-item
        >
          <v-sheet
              height="100%"


          >
            <v-row
                class="fill-height ma-16 pa-8"
                align="center"
                justify="center"

            >
              <v-col cols="12" md="4" sm="6">
                  <v-card
                      height="500"
                  >
                    <v-img
                        src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                        height="300px"
                    ></v-img>

                    <v-card-title>
                      Abdullah Al-Sulaiteen
                    </v-card-title>

                    <v-card-subtitle>
                      CEO,Founder,Com
                    </v-card-subtitle>
                  </v-card>
              </v-col>
              <v-col cols="12" md="2">
              </v-col>
              <v-col cols="12" md="6" sm="6" class="ma-0 pa-0">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                software like Aldus PageMaker including versions of Lorem Ipsum.
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>

    <br>
    <br>
    <br>
    <v-card

        flat
        tile
        class="red--text text-center"
    >
      <v-row no-gutters>
        <v-col
            cols="12"
            sm="4"
            md="4"
        >
<v-card-text
class="black--text font-weight-bold mx-1">
  Ahmad Al Jaber street,kuwait city,
  block 6
</v-card-text>
    <v-card-text>
      <v-btn
          v-for="icon in icons"
          :key="icon"
          class=" black--text"
          icon
      >
        <v-icon size="24px">
          {{ icon }}
        </v-icon>
      </v-btn>
    </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <landing-page-footer></landing-page-footer>
  </v-app>
</template>
<script>
import LandingPageFooter from "@/components/layouts/LandingPageFooter";
import LandingPageBanner from "@/components/layouts/LandingPageBanner";
import Navbar from "@/components/layouts/Navbar";

export default {
  name: 'About',

  components: {
    LandingPageBanner,
    Navbar,
    LandingPageFooter
  },

  data: () => ({
    model: 0,
    icons: [
      'mdi-facebook',
      'mdi-twitter',
      'mdi-linkedin',
      'mdi-instagram',
      'mdi-snapchat',
      'mdi-email-outline',
      'mdi-map-marker'
    ],
    colors: [
      'primary',
      'secondary',
      'yellow darken-2',
      'red',
      'orange',
    ],
    transparent: 'rgba(255, 255, 255, 0)',
    goal: require('@/assets/images/Goal.svg'),
    vision: require('@/assets/images/Vision.svg'),
    mission: require('@/assets/images/Mission.svg'),
    ethics: require('@/assets/images/Ethics.svg'),
    innovation: require('@/assets/images/Innovation.svg'),
    quality: require('@/assets/images/Quality & Comfort.svg'),
    collaboration: require('@/assets/images/Collaboration.svg'),
    sustainability: require('@/assets/images/Sustainibility.svg'),
  }),
  methods: {},
  mounted() {
  },
  created() {
    this.$vuetify.rtl = localStorage.getItem("rtl") === "true";
    this.$i18n.locale = localStorage.getItem('locale');
  }
};
</script>
<style lang="sass" scoped>
.v-card.on-hover.theme--dark
  background-color: rgba(#FFF, 0.8)

  > .v-card__text
    color: #000
</style>
<style scoped>
.neumorphic {
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.4),
  -12px -12px 24px 0 rgba(255, 255, 255, .1);
  width: 400px;
  height: 600px;
  border-radius: 40px;

}

.variation1 {
  background: linear-gradient(
      -45deg,
      rgba(0, 0, 0, 0.1),
      rgba(255, 255, 255, 0.5)
  );
  padding-bottom: 6px;
}

.img {
  height: 150px;
  width: 150px;
  background-color: #e5e3e3;
}

</style>